@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.projects {

    &__intro {
        margin-top: 5rem;
        margin-inline: 2rem;
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            align-items: center;
            margin-inline: 3rem;
            margin-bottom: 1rem;
        }

        @include desktop {
            margin-inline: 7rem;
        }

        &__header {
            text-align: center;
            padding-bottom: 1rem;
            font-size: 1.75rem;
            font-weight: 500;

            @include tablet {
                text-align: left;
                font-size: 2.5rem;
                margin-right: auto;
                max-width: 17%;
                margin-left: 2rem;
            }

            @include desktop {
                font-size: 3.5rem;
                font-weight: 400;
            }
        }

        &__paragraph {
            text-align: center;
            font-size: 0.9rem;
            font-weight: 300;
            color: #8A898E;
            line-height: 1.5rem;
            margin-bottom: 2rem;

            @include tablet {
                border-left: 1px solid $color-border;
                width: 50%;
                padding-right: 5%;
                padding-left: 3rem;
                text-align: left;
                margin-bottom: 0;
            }

            @include desktop {
                padding-block: 1rem;
                padding-right: 19%;
            }
        }
    }

    &__container {
        border: none;
        border: 10px;
        padding: 0;
        background-color: $color-main;

        @include tablet {
            border: 1px solid $color-border;
            border-radius: 49px 0 0 49px;
            padding: 2rem;
            padding-bottom: 3.5rem;
            margin-left: 3rem;
            margin-bottom: 5rem;
        }

        @include desktop {
            margin-left: 7rem;
        }

        @include desktop-cutoff {
            margin-right: -9.5rem;
        }
    }

    &__horizontal-line {
        border-top: 1px solid #3C3B47;
        position: relative;
        height: 1px;
        margin-bottom: 5rem; 
        margin-top: 6rem;
        margin-inline: -2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet {
            display: none;
        }
    }

    &__arrow-icon {
        height: 4rem;
        z-index: 10;
        margin-top: -3px;
        cursor: pointer;

        &:nth-of-type(1) {
            transform: scaleX(-1);
        }

        &--tablet {
            display: none;

            @include tablet {
                display: block;
                height: 3.5rem;
                cursor: pointer;
            }

            &:nth-of-type(1) {
                transform: scaleX(-1);
            }

            &:nth-of-type(2) {
                transform: scaleX(1);
            }
        }
    }

    &__arrows__flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline: auto;
        gap: 3rem;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
    }

    &__arrow-group  {
        display: none;

        @include tablet {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            left: 50%;
            margin-left: -3rem;
            transform: translateX(-50%);
            bottom: -5.1rem;
            width: auto;
            z-index: 500;
            gap: 3rem;
            z-index: 1000;
        }

        @include desktop {
            transform: translateX(-60%);
        }

        @include desktop-cutoff {
            transform: translateX(-100%);
        }
    }
}