@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.education {
    width: 100vw;

    &__overflow-container {
        overflow: hidden;
        margin-left: 2rem;

        @include tablet {
            width: 100vw;
            margin-inline: 3rem;
        }
    
        @include desktop {
            margin-inline: 7rem;
        }
    }

    &__header {
        font-size: 1.75rem;
        font-weight: 500;
        margin-bottom: 1rem;

        @include tablet {
            font-size: 2.1rem;
        }

        @include desktop {
            font-size: 2.5rem;
        }
    }

    &__card-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: max-content;
        transition: transform 0.3s ease-in-out;

        @include large {
            margin-bottom: 27rem;
        }
    }

    &__card {
        border-radius: 10px;
        background: $education-card-gradient;
        padding: 1rem 2rem 1rem 1rem;
        width: 12rem;
        height: 17rem;

        &:nth-child(4) {
            .education__card--download {
                opacity: 0.6; 
                pointer-events: none; 
                cursor: default; 
            }
        }

        &__checkbox {
            background-color: $color-main;
            border-radius: 9px;
            width: min-content;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.73rem 0.6rem;
            margin-bottom: 1rem;
        }

        &__check {
            height: 0.7rem;
        }

        &--degree {
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        &--institution {
            font-size: 0.75rem;
            color: $color-gray-text;
            opacity: 70%;
            margin-bottom: 1rem;
        }

        &--download {
            background: transparent;
            border: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 0.8rem;
            font-weight: 500;
            margin-bottom: 1rem;
            margin-top: auto;
            text-decoration: none;
            cursor: pointer;

            &-icon {
                height: 0.9rem;
                padding-right: 0.5rem;
            }
        }
    }

    &__swiper__buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline: auto;
        margin-left: -2rem;
        margin-bottom: 70vw;
        gap: 3rem;
        z-index: 100;
        width: 100%;

        @include tablet {
            margin-left: -3rem;
            margin-bottom: 40vw;
        }

        @include large {
            display: none;
        }

        &--next {
            height: 4rem;
            cursor: pointer;
        }

        &--previous {
            height: 4rem;
            cursor: pointer;
            transform: scaleX(-1);
        }
    }
}