@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.about {
    position: relative;
    border: 1px solid $color-border;
    border-radius: 36px;
    padding: 5.5rem 1.5rem 2rem 1.5rem;
    text-align: center;
    margin-bottom: 5rem;
    background-color: $color-main;

    @include tablet {
        padding: 1.5rem;
        border-radius: 49px;
        margin-inline: auto;
        width: 80%;
    }

    @include desktop {
        width: 70%;
        margin-bottom: 8rem;
    }

    &__circle-gradient {
        position: absolute;
        z-index: -1;
        height: 145vw;
        max-width: 100vw;
        top: 2rem;
        right: 50%;
        transform: translate(50%, -50%);

        @include tablet {
            display: none;
        }
    }

    &__elipse-gradient--1 {
        display: none;

        @include tablet {
            display: block;
            position: absolute;
            z-index: -1;
            height: 57.7rem;
            width: 32.8rem;
            top: 3rem;
            right: 50%;
            transform: translate(50%, -50%);
        }

        @include large {
            height: 69.24rem;
            width: 39.36rem;
        }

        @include desktop {
            height: 76.16rem;
            width: 43.3rem;
        }
    }

    &__elipse-gradient--2 {
        display: none;

        @include tablet {
            display: block;
            position: absolute;
            z-index: -1;
            height: 57.7rem;
            width: 42.7rem;
            top: -12.5rem;
            right: 20%;
        }

        @include large {
            height: 69.24rem;
            width: 51.24rem;
            top: -20rem;
        }

        @include desktop {
            height: 76.16rem;
            width: 56.36rem;
            top: -23rem;
        }
    }

    &__elipse-gradient--3 {
        display: none;

        @include tablet {
            display: block;
            position: absolute;
            z-index: -1;
            height: 57.7rem;
            width: 42.7rem;
            top: -12.5rem;
            left: 20%;
        }

        @include large {
            height: 69.24rem;
            width: 51.24rem;
            top: -20rem;
        }

        @include desktop {
            height: 76.16rem;
            width: 56.36rem;
            top: -23rem;
        }
    }

    &__outer-circle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -3.5rem;
        border: 1px solid $color-border;
        border-radius: 50%;
        height: 7rem;
        width: 7rem;
        background-color: $color-main;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__inner-circle {
        border: 1px solid $color-border;
        border-radius: 50%;
    }

    &__headshot-wrapper {
        border: 0px solid $color-border;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        width: 5rem;
    }

    &__headshot {
        height: 3rem;
        border-radius: 50%;
    }

    &__content {

        @include tablet {
            border: 1px solid $color-border;
            border-radius: 31px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__header {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 1.5rem;

            @include tablet {
                margin-top: 4rem;
                font-size: 2.1rem;
            }

            @include desktop {
                font-size: 2.5rem;
            }
        }

        &__paragraph {
            font-size: 0.9rem;
            font-weight: 300;
            color: $color-gray-text-form;
            line-height: 1.5rem;
            padding-bottom: 1.5rem;

            @include tablet {
                width: 75%;
            }

            @include desktop {
                width: 60%;
            }

            &--medium {
                font-weight: 600;
                color: $color-gray-text-form;
                text-decoration: none;

                &:hover {
                    color: $color-purple;
                }
            }
        }

        &__resume {
            display: flex;
            align-items: center;
            margin-inline: auto;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            background-color: $color-gray-button;
            padding: 0.7rem 1rem;
            font-size: 0.8rem;
            font-weight: 500;
            border: 1px solid $color-gray-button;
            border-radius: 5px;
            transition: background-color 0.3s, color 0.3s;
            text-decoration: none;
            width: max-content;
            cursor: pointer;

            @include tablet {
                margin-bottom: 3rem;
                margin-top: 1.5rem;
                font-size: 0.7rem;
                padding: 0.6rem 0.7rem;
            }

            @include desktop {
                font-size: 0.75rem;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.876);
                color: $color-main;

                svg {
                    fill: $color-main;
                    transition: fill 0.3s;
                }
            }

            svg {
                height: 0.9rem;
                margin-right: 0.6rem;
                fill: currentColor;

                @include tablet {
                    height: 0.85rem;
                }

                @include desktop-cutoff {
                    height: 1rem;
                }
            }
        }
    }
}