// Breakpoints 
$mobile-l-breakpoint: 900px;
$tablet-breakpoint: 768px; 
$desktop-breakpoint: 1280px;
$desktop-cutoff-breakpoint: 1400px;
$ultrawide-breakpoint: 2200px;

// Colors 
$color-main: #040212;
$color-white: #FFFFFF;
$color-orange: #E96C5C;
$color-purple: #7761FF;
$color-gray-card: #1D1C25;
$color-border: #3C3B47;
$color-gray-button: #181A1D;
$color-gray-text: #D2D1DB;
$color-gray-text-form: #8A898E;

// Gradient colors 
$gradient-card-full: rgba(29, 28, 37, 1);
$gradient-card-opaque: rgba(29, 28, 37, 0);
$gradient-elipse-orange: rgba(255, 135, 97, 1);
$gradient-elipse-purple: rgba(119, 97, 255, 0);

// Gradients 
$education-card-gradient: linear-gradient(to bottom, $gradient-card-full, $gradient-card-opaque);
$elipse-gradient: linear-gradient($gradient-elipse-orange, $gradient-elipse-purple); // might need direction 

$color-input-border: rgba(255, 255, 255, 0.5)