@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.navbar {
    display: flex;
    margin-top: 2rem;
    align-items: center;

    @include tablet {
        margin-inline: 3rem;
    }

    @include desktop {
        margin-inline: 7rem;
    }

    &__logo {
        height: 3.25rem;
        margin-inline: auto;
        z-index: 10;

        @include tablet {
            margin-inline: 0;
            margin-right: 4rem;
            cursor: pointer;
        }

        @include desktop {
            margin-right: 5rem;
        }
    }

    &__circle-gradient--wrapper {
        position: absolute; 
        right: 0;
        top: 0;
        width: 170vw; 
        height: 170vw; 
        overflow: hidden;
        z-index: -1;

        @include large {
            height: 140vw;
            width: 140vw;
        }

        @include desktop {
            height: 120vw;
            width: 120vw;
        }
    }

    &__circle-gradient {
        position: absolute;
        height: 170vw;
        width: 170vw;
        left: 0%;
        top: 0%;
        transform: translate(50%, -50%);

        @include large {
            height: 140vw;
            width: 140vw;
        }

        @include desktop {
            height: 120vw;
            width: 120vw;
        }
    }

    &__options {
        display: none;

        @include tablet {
            display: contents;
        }

        &__group {
            display: flex;
            gap: 4.5vw;

            @include desktop {
                gap: 2.8rem;
            }
            
            &--item {
                background: transparent;
                border: none;    
                font-size: 0.9rem;
                font-weight: 500;
                cursor: pointer;
                position: relative;
                display: inline-block; 
                padding-bottom: 5px;
                margin-bottom: -6px;

                @include tablet {

                    &:after {
                        content: '';
                        display: block;
                        margin-top: 5px; 
                        height: 3px; 
                        width: 0;
                        background: $color-orange;
                        transition: width 0.3s ease-in-out;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        cursor: pointer;
                    }
            
                    &:hover:after {
                        width: 100%;
                    }
                }
            }
        }

        &__contact {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-gray-button;
            border: none;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 500;
            padding-inline: 1.51rem;
            height: 2.7rem;
            margin-left: auto;
            cursor: pointer;

            @include tablet {
                &:hover {
                    background-color: $color-white;
                    color: $color-gray-button;
                    transition: background-color 0.3s, color 0.3s;
                }
            }

            @include desktop {
                padding-inline: 2.1rem;
            }
        }
    }

    &__burger-menu {
        position: absolute;
        height: 1.4rem;
        right: 1.5rem;
        top: 2.9rem;
        z-index: 10;
        cursor: pointer;

        @include tablet {
            display: none;
        }
    }
}