@use '../partials/variables' as *;

* { 
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: $color-white;
}

body {
    background-color: $color-main;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}