@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.skills {
    margin-inline: 2rem;
    margin-bottom: 9rem;

    @include tablet {
        margin-inline: 3rem;
        margin-bottom: 8rem;
    }

    @include desktop {
        margin-inline: 10rem;
    }

    &__header {
        font-size: 1.75rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;

        @include tablet {
            font-size: 2.1rem;
            text-align: left;
            width: 80%;
            margin-inline: auto;
        }

        @include desktop {
            font-size: 2.5rem;
        }
    }

    &__group-wrapper {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            width: 80%;
            margin-inline: auto;
        }
    }

    &__subgroup-wrapper {
        
        @include tablet {
            display: flex; 
            flex-direction: column;
            margin-right: 1.2rem;
        }
    }

    &__box {
        border-radius: 29px;
        margin-bottom: 1rem;
        padding: 2rem 1rem;

        &--frontend {
            background-image: linear-gradient(
                180deg,
                hsl(7deg 95% 58%) 1%,
                hsl(7deg 91% 65%) 50%,
                hsl(7deg 80% 71%) 99%
            );
        }

        &--backend {
            background-image: linear-gradient(
                180deg,
                hsl(247deg 14% 13%) 1%,
                hsl(249deg 3% 37%) 50%,
                hsl(0deg 0% 64%) 99%
            );
        }

        &--design {
            background-image: linear-gradient(
                180deg,
                hsl(248deg 100% 69%) 1%,
                hsl(249deg 100% 75%) 50%,
                hsl(248deg 100% 82%) 99%
            );
            
            @include tablet {
                padding-bottom: 0;
                position: relative;
            }

            &-image {
                display: none;

                @include tablet {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 50%; 
                    transform: translateX(-50%); 
                    max-height: 52%;
                    max-width: 80%;
                    height: auto;
                }

                @include desktop {
                    max-width: 100%;
                    max-height: 55%;
                }
            }
        }

        &__header {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            padding-left: 0.5rem;
        }

        &__list {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;

            &--item {
                background-color: $color-main;
                font-size: 0.8rem;
                padding: 0.4rem 1rem;
                border-radius: 144px;
                margin: 0.5rem;
                cursor: pointer;

                @include tablet {
                    &:hover {
                        background-color: $color-white;
                        color: $color-main;
                    }
                }
            }
        }
    }
}